const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://afugnr3uzh.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://8o1euakcfg.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://6r3xd4ymr4.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'develop',
    HOSTNAME: 'https://locations.testing.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://rs4k9t9nml.execute-api.us-west-2.amazonaws.com/testing'
  },
};

export default config;